<template>
  <div class="register-select app_content">
    <div class="warp_body">
      <div v-html="value"  >

      </div>
    </div>

  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import HeaderEle from '../components/common/headerEle'
import API from "../api/User";
import req from "../request/request";
import router from "../router";
import {useRoute} from "vue-router";


export default {
  name: 'ServiceProtocol',
  components: {HeaderEle},

  setup() {
    onBeforeMount(() => {
      const route = useRoute();
      methodsMap.getVal(route.name)
    });
    const data = reactive({
          value: ''
        },
    )

    const methodsMap = {
      getVal: (name) => {
        req.request(API.getRouteContent, {name: name}, {method: 'GET'}).then((res) => {
          data.value = res.data.data.value
          console.log(data.value)
        })
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {

    .registerSelect {
      width: 90%;
      margin: 40px auto;
    }

    .protocol {
      text-align: center;
      font-size: 10px;
      color: blue;
    }
  }
}
</style>
